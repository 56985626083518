import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UtilService {
  constructor() { }
  

    isNull(_var) {
        if (_var == null || _var == 'null' || _var == '' || _var == undefined || _var == 'undefined' || _var == 'NaN' || _var == NaN) {
            return true;
        } else {
        return false;
        }
    }
    isNotNull(_var) {
      if (_var != null || _var != 'null' || _var == '' || _var != undefined || _var != 'undefined' || _var != 'NaN' || _var != NaN) {
          return true;
      } else {
      return false;
      }
  }
    isEmail(email) {
        var emailExpression = /^[a-z][\w.-]+@\w[\w.-]+\.[\w.-]*[a-z][a-z]$/i;
        return emailExpression.test(email);
    }
    isNumeric(input) {
        return !isNaN(parseFloat(input)) && isFinite(input);
    }
    isMobile(input) {
        if (input.indexOf('09') > -1 
        || input.indexOf('08') > -1 
        || input.indexOf('07') > -1 
        || input.indexOf('06') > -1 
        || input.indexOf('05') > -1 
        || input.indexOf('04') > -1 
        || input.indexOf('03') > -1 
        || input.indexOf('02') > -1) {
            return !isNaN(parseFloat(input)) && isFinite(input) && input.length >= 10;
        } else {
            return false;
        }
    }

    notNull(input) {
      return input.length != '' || input.length != 0;
    }
    isUsername(input) {
      return input.length >= 8;
  }
    isPassword(input) {
        return input.length >= 8;
    }
    isdate(input) {
      return input.length != '';
  }
    isTel(input) {
      return input.length >= 10;
  }

  isMemberCode(input) {
    return input.length >= 19;
  }

  isLaserCode(input) {
    return input.length >= 12;
  }
    isThaiOnly(input) {
      var thaiOnltExpression = /[กขฃคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรฤลฦวศษสหฬอฮฯะัาำิีึืฺุูเแโใไๅๆ็่้๊๋์]/;
      return thaiOnltExpression.test(input);
  }
    isUndefined(st) {
        return st == undefined || st == null || st == '' || st == '0' || st == 0;
    }

    isPostal(ps) {
        return ps.length == 5 && this.isNumeric(ps)
    }

    isIdenNo(id) {
        if(! this.isNumeric(id)) return false;
        if(id.substring(0,1)== 0) return false;
        if(id.length != 13) return false;
        var i = 0;
        var sum = 0;
        for(i=0, sum=0; i < 12; i++)
            sum += parseFloat(id.charAt(i))*(13-i);
        if((11-sum%11)%10!=parseFloat(id.charAt(12))) return false;
        return true;
    }



    comparePassword(_p1,_p2){
      if(this.isPassword(_p1) && this.isPassword(_p2) && (_p1==_p2)){
        return true;
      }
      return false;
    }

    formatNumber(number, maxDecimals, forceDecimals,siStyle)
    {
      number = number || 0;
      maxDecimals = maxDecimals || 2;
      forceDecimals = forceDecimals || false;
      siStyle = siStyle || true; 
      
      var i = 0,inc = Math.pow(10,maxDecimals),str = String(Math.round(inc * Number(number)) / inc);
      var hasSep = str.indexOf(".") == -1,sep = hasSep ? str.length:str.indexOf(".");
      var ret = (hasSep && !forceDecimals ? "" : (siStyle ? "." : ",")) + str.substr(sep+1);
      if (forceDecimals)
      {
        for (var j = 0; j <= maxDecimals - (str.length - (hasSep ? sep-1 : sep)); j++)
        {
          ret +=  "0";
        }
      }
      while (i + 3 < (str.substr(0, 1) == "-" ? sep-1 : sep))
      {
        ret = (siStyle ? "," : ".") + str.substr(sep - (i += 3), 3) + ret;
      }
      return str.substr(0, sep - i) + ret;
    }

    jGet(name) {
        return decodeURI(
            (RegExp(name + '=' + '(.+?)(&|$)').exec(location.search) || [, null])[1]
        );
    }

    truncate(input,_lenght) {
        _lenght = _lenght || 150;
        if (input.length > _lenght)
            return input.substring(0, _lenght) + ' ...';
        else
            return input;
    };

    randomNumber(max,min)
    {
      max = max || 10;
      min = min || 0;
      var randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
      return (randomNum);
    }

}