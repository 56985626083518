import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { UtilService } from '../services/util.services'


@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {
  
  themeNone

  constructor(private router: Router,public util: UtilService)
  {}

  ngOnInit(): void {
  }

}
