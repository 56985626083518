import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private defualt_lang = 'th';
  private lang = new Subject<any>();

  constructor() {
    if(localStorage.getItem('lang')){
      this.defualt_lang = localStorage.getItem('lang');
    }
    this.lang.next({ val: this.defualt_lang });
   }

  setLang(_st: string) {
    this.lang.next({ val: _st });
    localStorage.setItem('lang',_st);
  }

  getLang(): Observable<any> {
      return this.lang.asObservable();
  }

  getDefualt(){
    if(localStorage.getItem('lang')){
      return localStorage.getItem('lang');
    }
      return this.defualt_lang;
  }
  
}
