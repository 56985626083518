import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHeaders,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()

export class HttpConfigInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const authReq = req.clone({
        // headers: new HttpHeaders({
        //     'Content-Type':  'application/json',
        //     'Authorization': 'my-auth-token'
        // })
      });
      return next.handle(authReq);
    }
  }
