import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menu = new Subject<any>();

  constructor() { }

  setMenu(_st: string) {
    this.menu.next({ text: _st });
  }

  clearMenu() {
      this.menu.next();
  }

  getMenu(): Observable<any> {
      return this.menu.asObservable();
  }
  
}
