<div class="block-under_construction">
    <div class="under_construction-body">
        <img class="logo" src="assets/image/construction-logo.png" alt="">
        <h3>{{'under_construction.uc_service1' | translate}}</h3>
        <p>{{'under_construction.uc_service2' | translate}}</p>
        <div class="under_construction-action">
            <button class="btn-default" [routerLink]="['/home']">
                <span>{{'under_construction.uc_home' | translate}}</span>
            </button>
        </div>
    </div>
    <div class="under_construction-foot">
        <img src="assets/image/bg-construction-city-bg.png" alt="">
    </div>
</div>