import { Component } from '@angular/core';
import { ThemeService } from '../app/core/theme.service';
import { Router } from "@angular/router";

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../app/services/language.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  subscription: Subscription;
  headerFooter
  themeHr
  themeMember
  themeDefault
  themeNone
  title = 'tisco';
  menu= '';

  constructor(private router:Router,public translate: TranslateService,public langService:LanguageService) { 
    this.subscription = this.langService.getLang().subscribe(lang => {
      this.translate.use(lang['val']);
    });

    translate.addLangs(['th', 'en']);
    translate.setDefaultLang(this.langService.getDefualt());


  }


}
