
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 

import { FormpatternDirective } from './formpattern.directive';

@NgModule({
    declarations: [FormpatternDirective],
    exports: [FormpatternDirective]
  })
export class FormpatternModule { }