import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appFormpattern]'
})
export class FormpatternDirective {
  constructor(private el: ElementRef) {
      
  }
  @Input('appFormpattern') pattern: string;
  @Input() notzero: boolean;
  @Input('setNumstart') numstart: number = 0;
  @Input('setNumend') numend: number = 0;


  @HostListener('input', ['$event']) onKeyInput(e) {
    var keyCodeChar = e.target.value.slice(-1);
    var st_pattern = "";
    if (this.pattern == "english")
        st_pattern = "[a-z.A-Z]";
    else if (this.pattern == "englishNumber")
    st_pattern = "[A-Za-z0-9]";
    else if (this.pattern == "thaionly")
        st_pattern = "[ภถุึคตจขชๆไำพะัีรนยบลฟหกดเ้่าสวงผปแอิืทมใฝฃูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ ]";
    else if (this.pattern == "thai")
        st_pattern = "[ภถุึคตจขชๆไำพะัีรนยบลฟหกดเ้่าสวงผปแอิืทมใฝฃูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ A-Za-z -]";
    else if (this.pattern == "address")
        st_pattern = "[ภถุึคตจขชๆไำพะัีรนยบลฟหกดเ้่าสวงผปแอิืทมใฝฃูฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ A-Za-z0-9+#.-\/ ()]"
    else if (this.pattern == "passport")
        st_pattern = "[a-z_.0-9\\-\\^]"
    else if (this.pattern == "number")
        st_pattern = "[0-9]"
    else if (this.pattern == "numberslash")
        st_pattern = "[0-9\/\-]"
    else if (this.pattern == "numberpoint")
        st_pattern = "[0-9.]"
    else if (this.pattern == "date")
    st_pattern = "[]"
    else if (this.pattern == "email")
        st_pattern = "[a-z_.@0-9\\-\\^]"
    else if (this.pattern == "password")
        st_pattern = "[a-z_.@0-9\\-\\^]"
    else if (this.pattern == "unchar")
        st_pattern = "[]"

    if (!keyCodeChar.match(new RegExp(st_pattern, "i"))) {
        var inputString = e.target.value;
        var shortenedString = inputString.substr(0, (inputString.length - 1));
        e.target.value = shortenedString
        return false;
    }
  }

  @HostListener('focus', ['$event']) onFocusin(e) {
    if(this.notzero){
        if(e.target.value==0){
            e.target.value = '';
        }
    }
  }

  @HostListener('focusout', ['$event']) onFocusOut(e) {
    if(this.notzero){
        if(e.target.value==''){
            e.target.value = 0;
        }
    }
    // console.log('numstart : '+this.numstart)
    if(this.numstart>0){
        if(Number(e.target.value)<this.numstart){
            e.target.value = this.numstart;
        }
    }

    if(this.numend>0){
        if(Number(e.target.value)>this.numend){
            e.target.value = this.numend;
        }
    }

  }


}
