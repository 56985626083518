import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnderConstructionComponent } from './under-construction/under-construction.component';

const routes: Routes = [

  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'qa', loadChildren: () => import('./qa/qa.module').then(m => m.QaModule) },
  { path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },
  { path: 'form', loadChildren: () => import('./problem-form/problem-form.module').then(m => m.ProblemFormModule) },
  { path: 'form-reserve', loadChildren: () => import('./reserve-form/reserve-form.module').then(m => m.ReserveFormModule) },
  { path: 'form-reserve/registration', loadChildren: () => import('./reserve-form-register/reserve-form-register.module').then(m => m.ReserveFormRegisterModule) },
  { path: 'form-reserve/member', loadChildren: () => import('./reserve-form-member/reserve-form-member.module').then(m => m.ReserveFormMemberModule) },
  { path: 'registration', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'register-ref-code', loadChildren: () => import('./register-ref-code/register-infomation-thai.module').then(m => m.RegisterRefCodeModule) },
  { path: 'register-information-thai', loadChildren: () => import('./register-infomation-thai/register-infomation-thai.module').then(m => m.RegisterInfomationThaiModule) },
  { path: 'register-information-foreign', loadChildren: () => import('./register-infomation-foreign/register-infomation-foreign.module').then(m => m.RegisterInfomationForeignModule) },
  { path: 'register-create-user-password', loadChildren: () => import('./register-create-user-password/register-create-user-password.module').then(m => m.RegisterCreateUserPasswordModule) },
  { path: 'register-create-user-otp', loadChildren: () => import('./register-create-user-otp/register-create-user-otp.module').then(m => m.RegisterCreateUserOtpModule) },
  { path: 'register-root-hr', loadChildren: () => import('./register-root-hr/register-root-hr.module').then(m => m.RegisterRootHrModule) },
  { path: 'register-root-member', loadChildren: () => import('./register-root-member/register-root-member.module').then(m => m.RegisterRootMemberModule) },
  { path: 'policy', loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule) },
  { path: 'term', loadChildren: () => import('./term/term.module').then(m => m.TermModule) },
  { path: 'disclaimer', loadChildren: () => import('./disclaimer/disclaimer.module').then(m => m.DisclaimerModule) },
  { path: 'mybalance', loadChildren: () => import('./my-balance/my-balance.module').then(m => m.MybalanceModule) },
  { path: 'statusresign', loadChildren: () => import('./resign-status/resign-status.module').then(m => m.StatusresignModule) },
  { path: 'forgot/mail', loadChildren: () => import('./forgot-mail/forgot-mail.module').then(m => m.ForgotmailModule) },
  { path: 'forgot/password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotpasswordModule) },
  { path: 'risk', loadChildren: () => import('./member-risk-profile-linkout/member-risk-profile-linkout.module').then(m => m.MemberRiskProfileLinkoutModule) },
  { path: 'risk/check', loadChildren: () => import('./member-risk-profile-linkout-check/member-risk-profile-linkout-check.module').then(m => m.MemberRiskProfileLinkoutCheckModule) },
  { path: 'risk/point', loadChildren: () => import('./member-risk-profile-linkout-point/member-risk-profile-linkout-point.module').then(m => m.MemberRiskProfileLinkoutPointModule) },
  
  // HR
  
  { path: 'hr/dashboard', loadChildren: () => import('./hr/hr-dashboard/hr-dashboard.module').then(m => m.HrDashboardModule) },
  { path: 'hr/registration', loadChildren: () => import('./hr/hr-register/hr-register.module').then(m => m.HRegisterModule) },
  { path: 'hr/disclaimer', loadChildren: () => import('./hr/hr-disclaimer/hr-disclaimer.module').then(m => m.HrDisclaimerModule) },
  { path: 'hr/password', loadChildren: () => import('./hr/hr-password/hr-password.module').then(m => m.HrPasswordModule) },
  { path: 'hr/company', loadChildren: () => import('./hr/hr-company/hr-company.module').then(m => m.HrCompanyModule) },
  { path: 'hr/member', loadChildren: () => import('./hr/hr-member/hr-member.module').then(m => m.HrMemberModule) },
  { path: 'hr/switching', loadChildren: () => import('./hr/hr-switching/hr-switching.module').then(m => m.HrSwitchingModule) },
  { path: 'hr/report', loadChildren: () => import('./hr/hr-report/hr-report.module').then(m => m.HrReportModule) },
  { path: 'hr/risk', loadChildren: () => import('./hr/hr-risk/hr-risk.module').then(m => m.HrRiskModule) },
  { path: 'hr/qa', loadChildren: () => import('./hr/hr-qa/hr-qa.module').then(m => m.HrQaModule) },
  { path: 'hr/setting', loadChildren: () => import('./hr/hr-setting/hr-setting.module').then(m => m.HrSettingModule) },
  { path: 'hr/notification', loadChildren: () => import('./hr/hr-notification/hr-notification.module').then(m => m.HrNotificationModule) },
  { path: 'hr/popup', loadChildren: () => import('./hr/hr-popup/hr-popup.module').then(m => m.HrPopupModule) },

  // MEMBER
  { path: 'member/disclaimer', loadChildren: () => import('./member-disclaimer/member-disclaimer.module').then(m => m.MemberDisclaimerModule) },
  { path: 'member/dashboard', loadChildren: () => import('./member/member-dashboard/member-dashboard.module').then(m => m.MemberDashboardModule) },
  { path: 'member/help', loadChildren: () => import('./member/member-help/member-help.module').then(m => m.MemberHelpModule) },
  { path: 'member/qa', loadChildren: () => import('./member/member-qa/member-qa.module').then(m => m.MemberQaModule) },
  { path: 'member/registration', loadChildren: () => import('./member/member-register/member-register.module').then(m => m.MemberRegisterModule) },
  { path: 'member/report', loadChildren: () => import('./member/member-report/member-report.module').then(m => m.MemberReportModule) },
  { path: 'member/risk', loadChildren: () => import('./member/member-risk/member-risk.module').then(m => m.MemberRiskrModule) },
  { path: 'member/profile', loadChildren: () => import('./member/member-profile/member-profile.module').then(m => m.MemberProfileModule) },
  { path: 'member/profile', loadChildren: () => import('./member/member-profile/member-profile.module').then(m => m.MemberProfileModule) },
  { path: 'member/switching', loadChildren: () => import('./member/member-switch/member-switch.module').then(m => m.MemberSwitchModule) },
  { path: 'member/notification', loadChildren: () => import('./member/member-notification/member-notification.module').then(m => m.MemberNotificationModule) },
  { path: 'member/popup', loadChildren: () => import('./member/member-popup/member-popup.module').then(m => m.MemberPopupModule) },

  { path: '**', component: UnderConstructionComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
