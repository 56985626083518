
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient,HttpClientModule, HTTP_INTERCEPTORS } from  '@angular/common/http';
import { NgModule } from '@angular/core';

import { RequestCache, RequestCacheWithMap } from './core/request-cache.service';
import { HttpErrorHandler } from './core/http-error-handler.service';
import { MessageService } from './core/message.service';
import { HttpConfigInterceptor } from './core/httpconfig.interceptor';
import { MenuService } from './services/menu.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormpatternModule } from './directive/formpattern.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


@NgModule({
  declarations: [
    AppComponent,
    UnderConstructionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    FormpatternModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    HttpErrorHandler,
    MessageService,
    MenuService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: RequestCache, useClass: RequestCacheWithMap },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}